import { render, staticRenderFns } from "./TwoPanel.vue?vue&type=template&id=59c5d609&scoped=true&"
import script from "./TwoPanel.vue?vue&type=script&lang=js&"
export * from "./TwoPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59c5d609",
  null
  
)

export default component.exports